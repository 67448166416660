body {
  display: flex;
  flex-direction: column;

  /* 
    script でセットする min-height 用のワークアラウンド
    https://stackoverflow.com/a/21836870
  */
  height: 1px;
}

#root {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  height: 100%;
  overflow-y: auto;
}
